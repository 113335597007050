import React from 'react'
import * as Global from './global/Global';

function Missing() {
    const getFrequency=(v)=>{
        let f=0;
        return localStorage.getItem('dd')!=="" && localStorage.getItem('mm')!=="" && localStorage.getItem('yyyy')!=="" && Global.getGridDigits().split("").filter(c=>{return c===v && v}).join(", ").toString();
    }
    return (
        localStorage.getItem('dd')!=="" && localStorage.getItem('mm')!=="" && localStorage.getItem('yyyy')!=="" ?
        <React.Fragment>
            <table className="gridtable">
                
<tr>
    <td style={{color:'#c00'}}> {!Global.getGridDigits().split("").includes("4") && "4"}</td>
    <td style={{color:'#c00'}}> {!Global.getGridDigits().split("").includes("9") && "9"}</td>
    <td style={{color:'#c00'}}> {!Global.getGridDigits().split("").includes("2") && "2"}</td>
    </tr>
<tr>
    <td style={{color:'#c00'}}> {!Global.getGridDigits().split("").includes("3") && "3"}</td>
    <td style={{color:'#c00'}}> {!Global.getGridDigits().split("").includes("5") && "5"}</td>
    <td style={{color:'#c00'}}> {!Global.getGridDigits().split("").includes("7") && "7"}</td>
    </tr>
<tr>
    <td style={{color:'#c00'}}> {!Global.getGridDigits().split("").includes("8") && "8"}</td>
    <td style={{color:'#c00'}}> {!Global.getGridDigits().split("").includes("1") && "1"}</td>
    <td style={{color:'#c00'}}> {!Global.getGridDigits().split("").includes("6") && "6"}</td>
    </tr>
                </table>
                <p>
In missing digits we try to know which aspect of your life is weaker as you are not getting vibration of that particular digit. You may improve Vastu of that direction as remedy for missing digits.
        </p>
                <p>Above digits are missing in your chart, so obviously you will not get benefits related to these digits. Similarly direction associated to these digits will be disturbing or not suitable for you.
                    You are advised to do remedy for your missing numbers so that you get benefits of those digits too or at your office or residence you need to take extra care of these directions.
                </p>
                {Global.getMissingPreds()}
        </React.Fragment>
        :
        <p>
In missing digits we try to know which aspect of your life is weaker as you are not getting vibration of that particular digit. You may improve Vastu of that direction as remedy for missing digits. Fill your data to get missing digits and their impact.
        </p>
    )
}

export default Missing
