import React from 'react';
import * as Global from './global/Global';

function Remedy() {
    return (
        <React.Fragment>
            <p>Some remedy is given as per missing digits in your chart, however you can consult a numerologist. In general if you keep your conduct good with labour class or poor people and devote your self and ego to god many remedy will be done automatically.</p>
            <div style={{textAlign:'center', fontSize:24, }}>जा पर कृपा राम की होई |<br />
ता पर कृपा करे सब कोई || </div>
<p>It is not always advisable to do remedy for all your missing digits as may be some missing digits are not favourable for you.</p>
            {localStorage.getItem('dd')!=="" && localStorage.getItem('mm')!=="" && localStorage.getItem('yyyy')!=="" && Global.getRemedy()}            
        </React.Fragment>
    )
}

export default Remedy
