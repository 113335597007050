import React, {useState, useEffect} from 'react'
import {Row, Col} from 'react-bootstrap';
import * as Global from './global/Global';
import './style.css';
function Info(props) {
    const grid=[4,9,2,3,5,7,8,1,6];
    const [dd, setDD]=useState(localStorage.getItem('dd') || new Date().getDate());
    const [mm, setMM]=useState(localStorage.getItem('mm') || new Date().getMonth()+1);
    const [yyyy, setYYYY]=useState(localStorage.getItem('yyyy') || new Date().getFullYear());
    const [dob, setDob]=useState(localStorage.getItem('dob') || new Date(dd+'/'+mm+'/'+yyyy));
    const [name, setName]=useState(localStorage.getItem('name') || "Native");
    const [gender, setGender]=useState(localStorage.getItem('gender') || 'Male');
    
    const updateDOB=()=>{
        localStorage.setItem('dd',dd);
        localStorage.setItem('mm',mm);
        localStorage.setItem('yyyy',yyyy);
        localStorage.setItem('gender', gender);
        localStorage.setItem('name', name);
        props.predChange();
    }
    const setDate=(v)=>{
        let dateV=[31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        v>=0 && v<=dateV[mm-1] ? setDD(v) : setDD("");
    }
    const setMonth=(v)=>{
        v >= 0 && v <= 12 ? setMM(v) : setMM("");
    }
    const setYear=(v)=>{
        v>=0 ? setYYYY(v) : setYYYY("");
    }
    const getFrequency=(v)=>{
        let f=0;
        return dd!=="" && mm!=="" && yyyy!=="" && Global.getGridDigits().split("").filter(c=>{return c===v && v}).join(", ").toString();
    }
    useEffect(()=>{
        updateDOB();
        setDob(new Date(dd+'/'+mm+'/'+yyyy));
    },[dd, mm, yyyy]);
    return (
        <React.Fragment>
            <Row>
                <Col><label>Enter Name</label><input type="text" className="form-control" value={name} onChange={(e)=>{localStorage.setItem('name', e.target.value); setName(e.target.value); props.predChange();}} placeholder="Enter Your Name" /></Col>
            </Row>
            <Row>
                <Col>
                <input type="radio" name="g" onChange={(e)=>{localStorage.setItem('gender', 'Male'); setGender('Male'); props.predChange();}} checked={gender==='Male' ? true : false} /> Male 
                    <input type="radio" name="g" onChange={(e)=>{localStorage.setItem('gender', 'Female'); setGender('Female'); props.predChange();}} checked={gender==='Female' ? true : false}  /> Female 
                    <input type="radio" name="g" onChange={(e)=>{localStorage.setItem('gender', 'Other'); setGender('Other'); props.predChange();}} checked={gender==='Other' ? true : false}  /> Other
                    </Col>
            </Row>
           <Row>
                    <Col sm={4} xs={12}><label>Month</label><input type="text" className="form-control" value={mm} onChange={(e)=>{setMonth(e.target.value);}} placeholder="MM" maxLength="2" /></Col>
                    <Col sm={4} xs={12}><label>Date</label><input type="text" className="form-control" value={dd} onChange={(e)=>{setDate(e.target.value);}} placeholder="DD" maxLength="2" /></Col>
                    <Col sm={4} xs={12}><label>Year</label><input type="text" className="form-control" value={yyyy} onChange={(e)=>{setYear(e.target.value);}} placeholder="YYYY" maxLength="4" /></Col>
                </Row> 
                <Row><Col><br />
                <table className="gridtable">
                    <tr><th style={{textAlign:'center'}}></th><th style={{textAlign:'center'}}>SE</th><th style={{textAlign:'center'}}>S</th><th style={{textAlign:'center'}}>SW</th><th></th></tr>
<tr>
    <th style={{textAlign:'center'}}>SE</th>
    <td> {Global.getGridDigits().split("").includes("4") && getFrequency("4")}</td>
    <td> {Global.getGridDigits().split("").includes("9") && getFrequency("9")}</td>
    <td> {Global.getGridDigits().split("").includes("2") && getFrequency("2")}</td>
    <th style={{textAlign:'center'}}>SW</th>
    </tr>
<tr>
    <th style={{textAlign:'center'}}>E</th>
    <td> {Global.getGridDigits().split("").includes("3") && getFrequency("3")}</td>
    <td> {Global.getGridDigits().split("").includes("5") && getFrequency("5")}</td>
    <td> {Global.getGridDigits().split("").includes("7") && getFrequency("7")}</td>
    <th style={{textAlign:'center'}}>W</th>
    </tr>
<tr>
    <th style={{textAlign:'center'}}>NE</th>
    <td> {Global.getGridDigits().split("").includes("8") && getFrequency("8")}</td>
    <td> {Global.getGridDigits().split("").includes("1") && getFrequency("1")}</td>
    <td> {Global.getGridDigits().split("").includes("6") && getFrequency("6")}</td>
    <th style={{textAlign:'center'}}>NW</th>
    </tr>
    <tr><th style={{textAlign:'center'}}></th><th style={{textAlign:'center'}}>NE</th><th style={{textAlign:'center'}}>N</th><th style={{textAlign:'center'}}>NW</th><th></th></tr>
                </table>
                <br />
                {dd!=="" && mm!=="" && yyyy!=="" ? <React.Fragment><h6>Birthdate Number: {Global.getBirthDate()[1]}</h6><h6>Lifepath Number: {Global.getLifePath()[1]}</h6><h6> Kua Number: {Global.kuaNumber()}</h6></React.Fragment> : null}
                <h5>Planets and Element</h5>
                <div className='table-responsive'>
                <table className="table table-bordered">
                    <thead>
                        <tr><th>Number</th><th>Planet</th><th>Element</th></tr>
                    </thead>
        <tbody>
        <tr><td>1</td><td>Sun</td><td>Water</td></tr>
        <tr><td>2</td><td>Moon</td><td>Earth</td></tr>
        <tr><td>3</td><td>Jupiter</td><td>Wood</td></tr>
        <tr><td>4</td><td>Rahu</td><td>Wood</td></tr>
        <tr><td>5</td><td>Mercury</td><td>Earth</td></tr>
        <tr><td>6</td><td>Venus</td><td>Metal</td></tr>
        <tr><td>7</td><td>Ketu</td><td>Metal</td></tr>
        <tr><td>8</td><td>Saturn</td><td>Earth</td></tr>
        <tr><td>9</td><td>Mars</td><td>Fire</td></tr>
        </tbody>
        </table>
        </div>
                </Col>
                </Row>
                </React.Fragment>
    
    
    )
}

export default Info
