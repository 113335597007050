import React, {useState} from 'react'
import Info from './Info'
import {Row, Col} from 'react-bootstrap';
import * as Global from './global/Global';
function Chart() {
// const [pred, setPred]=useState(0);
// const changePred=()=>{
//     setPred(pred*-1);
// }
    return (
//         <Row>
//             <Col sm={4} xs={12}>
                
// <Info predChange={()=>changePred()}/>
//             </Col>
//             <Col>
<React.Fragment>
            {
                localStorage.getItem('dd')!=='' && localStorage.getItem('mm')!=='' && localStorage.getItem('yyyy')!=='' ?
            <p className="prediction">
                <p>
                <p>Loshu is a way to reveal secretes of your life using your date of birth. We analyse date of birth with
            different aspects and know which side of our personality is strong and where we need to work. If we analyse 
            correctly we can remove many hurdles of our life with simple steps as remedy.</p>
            <p>Every digit in your chart represents different planet, nature and behavior</p>
            <p>Digits are placed in the chart from date of birth ignoring 0.  
                If a digit is appearing multiple times or missing in your chart then it is called DOSHA in your chart.</p>
                <p>You can do simple and effective Remedy for your missing digits but it is not advisable to do remedy for all missing digits.</p>
            </p>
                <h5 className="predshead">Birth Date Number ({Global.getBirthDate()[1]}): </h5>
                Your Birthdate number is your actual personality. {Global.getBirthDate()[1]} tells: 
                {Global.getBirthdatePreds()}
                <h5 className="predshead">Life Path Number ({Global.getLifePath()[1]}): </h5>
                Your Lifepath number is the way how your life will be driven. {Global.getLifePath()[1]} tells: 
                {Global.getLifepathPreds()}
                <p>{Global.getCombo()}</p>
                {/* <h5 className="predshead">Chart Numbers</h5> */}
                <hr />
                {Global.getChartPreds()}
            </p>
            :
            <p>
                <p>Loshu is a way to reveal secretes of your life using your date of birth. We analyse date of birth with
            different aspects and know which side of our personality is strong and where we need to work. If we analyse 
            correctly we can remove many hurdles of our life with simple steps as remedy.</p>
            <p>Every digit in your chart represents different planet, nature and behavior</p>
            <p>Digits are placed in the chart from date of birth ignoring 0.  
                If a digit is appearing multiple times or missing in your chart then it is called DOSHA in your chart.</p>
                <p>You can do simple and effective Remedy for your missing digits but it is not advisable to do remedy for all missing digits.</p>
            </p>
}
{console.log(Global.getUniqueGridDigit())}
            </React.Fragment>)
            {/* {Global.getGridDigits()} */}
        //     </Col>
        // </Row>
    // )
}

export default Chart
