import React from 'react';
class ErrorBoundry extends React.Component{
    constructor(props){
        super(props);
        this.state={hasError:false}
    }
    componentDidCatch(error, info){
        this.setState({hasError:true});
    }
    render(){
        return this.state.hasError ? <h4>Something went wrong ! Please try again.</h4> : this.props.children; 
    }
}
export default ErrorBoundry;