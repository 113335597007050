import React from 'react'
import * as Global from './global/Global';

function Planes() {
    const getFrequency=(v)=>{
        let f=0;
        return localStorage.getItem('dd')!=="" && localStorage.getItem('mm')!=="" && localStorage.getItem('yyyy')!=="" && Global.getGridDigits().split("").filter(c=>{return c===v && v}).join(", ").toString();
    }
    return (
        localStorage.getItem('dd')!=="" && localStorage.getItem('mm')!=="" && localStorage.getItem('yyyy')!=="" ?
        <React.Fragment>
            <table className="gridtable">
                <tr><th></th><th style={{textAlign:'center'}}>Thought {Global.getThought()}%</th><th style={{textAlign:'center'}}>Will {Global.getWill()}%</th><th style={{textAlign:'center'}}>Action {Global.getAction()}%</th></tr>
<tr>
    <th style={{textAlign:'right'}}>Mind {Global.getMind()}%</th>
    <td> {Global.getGridDigits().split("").includes("4") && getFrequency("4")}</td>
    <td> {Global.getGridDigits().split("").includes("9") && getFrequency("9")}</td>
    <td> {Global.getGridDigits().split("").includes("2") && getFrequency("2")}</td>
    </tr>
<tr>
    <th style={{textAlign:'right'}}>Soul {Global.getSoul()}%</th>
    <td> {Global.getGridDigits().split("").includes("3") && getFrequency("3")}</td>
    <td> {Global.getGridDigits().split("").includes("5") && getFrequency("5")}</td>
    <td> {Global.getGridDigits().split("").includes("7") && getFrequency("7")}</td>
    </tr>
<tr>
    <th style={{textAlign:'right'}}>Practical {Global.getPractical()}%</th>
    <td> {Global.getGridDigits().split("").includes("8") && getFrequency("8")}</td>
    <td> {Global.getGridDigits().split("").includes("1") && getFrequency("1")}</td>
    <td> {Global.getGridDigits().split("").includes("6") && getFrequency("6")}</td>
    </tr>
                </table>
                <p className="prediction">In your chart all numbers participate in making your capability in different aspect like <strong>Thought</strong>, <strong>Will</strong>, <strong>Action</strong>, <strong>Mind</strong>, <strong>Soul</strong>, <strong>Practical</strong>, presence and absence of any number suggest your strong and weaker areas. You can think which area you need to do some remedy to make it strong.

                </p>
                <p className='prediction'>
                    <h5 className='predshead'>Mind Plane {Global.getMind()}%</h5>
                    The Mind Plane talks about thinking capacity of the person. It reflects the powers of memory, intellect, analyzing capability, and thought.
                </p>
                <p className='prediction'>
                    <h5 className='predshead'>Soul Plane {Global.getSoul()}%</h5>
                    It reflects sensitivity, freedom, love, artistic inclination, spiritual independence and intuition.
                </p>
                <p className='prediction'>
                    <h5 className='predshead'>Practical Plane {Global.getPractical()}%</h5>
                    This plane represents practical abilities, organizational skills, materialistic abilities, verbal expression and the ability to do physical specially with one’s hand.
                </p>
                <p className='prediction'>
                    <h5 className='predshead'>Thought Plane {Global.getThought()}%</h5>
                    It reflects the ability of a person to think about new ideas. It also reflects the person’s love of order and method. This plane is therefore even referred to as ‘The Plane of the Planner’.
                </p>
                <p className='prediction'>
                    <h5 className='predshead'>Will Plane {Global.getWill()}%</h5>
                    It reflects a person’s determination and will to succeed. It also shows a person’s patience and persistence in realizing his goals.
                </p>
                <p className='prediction'>
                    <h5 className='predshead'>Action Plane {Global.getAction()}%</h5>
                    It revolves around the individual’s ability to put his ideas and ability in action and execute them.
                </p>
        </React.Fragment>
        :
        <p>
            <table className="gridtable">
                <tr><th></th><th style={{textAlign:'center'}}>Thought</th><th style={{textAlign:'center'}}>Will</th><th style={{textAlign:'center'}}>Action</th></tr>
<tr>
    <th style={{textAlign:'right'}}>Mind</th>
    <td>4</td>
    <td>9</td>
    <td>2</td>
    </tr>
<tr>
    <th style={{textAlign:'right'}}>Soul</th>
    <td>3</td>
    <td>5</td>
    <td>7</td>
    </tr>
<tr>
    <th style={{textAlign:'right'}}>Practical</th>
    <td>8</td>
    <td>1</td>
    <td>6</td>
    </tr>
                </table>
            Each row and column represents different ability of your personality you can analyse which part is strong and where you need some support. 
            <p>You will be amazed by seeing the result and it is really helpful. You should try it, just fill your data.</p>
        </p>
    )
}

export default Planes
