import React, {useState, useEffect, useRef} from 'react'
import logo from '../loshu.png';

function Map() {
    const mymap=useRef();
    const [status, setStatus]=useState("Fetching...");
const [center, setCenter]=useState({});
    const latlng={lat: -34.397, lng: 150.644};
const zoom=16;

useEffect(()=>{
    getGeoLocation();
    setInterval(getGeoLocation, 100000);
}, []);
useEffect(()=>{
    if(window.google){
  const map=new window.google.maps.Map(mymap.current, {
    center: center,
    zoom: zoom
  });
  new window.google.maps.Marker({
    position: center,
    map: map,
  });
  new window.google.maps.Marker({
    position: {lat:center.lat+0.0002, lng:center.lng+0.0002},
    map: map,
  });
}
}, [center, zoom]);


const getGeoLocation=()=>{
if(!navigator.geolocation){
setStatus("Geo Location not supported.");
}else{
    navigator.geolocation.getCurrentPosition((position)=>{setStatus(null); setCenter({lat:position.coords.latitude, lng:position.coords.longitude})});
}

}
  return (
      <div ref={mymap} style={{width:'100%', height:300}} ></div>
  )
}

export default Map